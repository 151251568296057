@font-face {
    font-family: "HelveticaNeueLTStd-Lt";
    src: url("../fonts/HelveticaNeueLTStd-Lt.eot");
    src: url("../fonts/HelveticaNeueLTStd-Lt.eot?#iefix") format("embedded-opentype"),
         url("../fonts/HelveticaNeueLTStd-Lt.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTStd-Lt.woff") format("woff"),
         url("../fonts/HelveticaNeueLTStd-Lt.ttf") format("ttf"),
         url("../fonts/HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt") format("svg");
    font-style: normal;
    font-weight: 300;
}