@font-face {
    font-family: "HelveticaNeueLTStd-Blk";
    src: url("../fonts/HelveticaNeueLTStd-Blk.eot");
    src: url("../fonts/HelveticaNeueLTStd-Blk.eot?#iefix") format("embedded-opentype"),
         url("../fonts/HelveticaNeueLTStd-Blk.woff2") format("woff2"),
         url("../fonts/HelveticaNeueLTStd-Blk.woff") format("woff"),
         url("../fonts/HelveticaNeueLTStd-Blk.ttf") format("ttf"),
         url("../fonts/HelveticaNeueLTStd-Blk.svg#HelveticaNeueLTStd-Blk") format("svg");
    font-style: normal;
    font-weight: 900;
}