@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@400;600;800&family=Sura:wght@400;700&display=swap");
@font-face {
  font-family: "HelveticaNeueLTStd-Blk";
  src: url("../fonts/HelveticaNeueLTStd-Blk.eot");
  src: url("../fonts/HelveticaNeueLTStd-Blk.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTStd-Blk.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-Blk.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-Blk.ttf") format("ttf"), url("../fonts/HelveticaNeueLTStd-Blk.svg#HelveticaNeueLTStd-Blk") format("svg");
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "HelveticaNeueLTStd-Lt";
  src: url("../fonts/HelveticaNeueLTStd-Lt.eot");
  src: url("../fonts/HelveticaNeueLTStd-Lt.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueLTStd-Lt.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-Lt.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-Lt.ttf") format("ttf"), url("../fonts/HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt") format("svg");
  font-style: normal;
  font-weight: 300;
}
.font-bitter {
  font-family: "Bitter", serif;
}

.font-Sura {
  font-family: "Sura", serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}

p {
  @apply prose;
}

.lead {
  @apply text-xl font-medium;
}

.font-title {
  font-family: HelveticaNeueLTStd-Blk, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.font-lead {
  font-family: HelveticaNeueLTStd-Lt, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.dynamic-title {
  @apply text-primary;
}

.card {
  @apply p-4 border-2 rounded-md;
}

.reponsive-grid {
  @apply grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.reponsive-grid-sm {
  @apply grid;
  grid-template-columns: repeat(auto-fit, minmax(25ch, 1fr));
}

.w-inherit {
  width: inherit;
  max-width: inherit;
}

@media (min-width: 640px) {
  .w-screen-50 {
    width: calc(50vw - 2rem);
  }
}
.max-w-35 {
  max-width: 35rem;
}