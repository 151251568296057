@use './HelveticaNeueLTStd-Blk.css';
@use './HelveticaNeueLTStd-Lt.css';
@use './google-fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    scroll-behavior: smooth;
}

p{
    @apply prose;
}

.lead{
    @apply text-xl font-medium;
}

.font-title{
    font-family: HelveticaNeueLTStd-Blk, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.font-lead{
    font-family: HelveticaNeueLTStd-Lt, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.dynamic-title{
    @apply text-primary;
}

.card{
    @apply  p-4 border-2 rounded-md;
}

.reponsive-grid{
    @apply grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.reponsive-grid-sm{
    @apply grid;
    grid-template-columns: repeat(auto-fit, minmax(25ch, 1fr));
}

.w-inherit{
    width: inherit;
    max-width:inherit;
}

@media (min-width: 640px) {
    .w-screen-50{
        width: calc( 50vw - 2rem)
    }
}

.max-w-35{
    max-width: 35rem;
}